import { useEffect, useState } from "react";
import JobsComponent from "../../../Components/Admin/Jobs";
import JOB_API from "../../../Api/api";
import 'moment/locale/fr';
import { isValidJson, openNotification, reloadPage } from "../../../Util/commonUtility";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined, DownloadOutlined, EyeOutlined, InfoCircleOutlined, SortUpIcon, SortDownIcon } from '@ant-design/icons';
import { Popconfirm, Tooltip, Spin } from 'antd';
import { commonError, deleteError, deleteSuccess, resumeError, resumeSuccess } from "../../../Util/config";
import Urls from "../../../Constant/Urls";
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';


const JobContainer = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortTitle, setSortTitle] = useState('Click to Sort Descending');
    let navigate = useNavigate();
    const [searchData, setSearchData] = useState();
    const [sortField, setSortField] = useState('createdAt');
    const [pssData, setpssData] = useState();
    const [allPssData, setAllPssData] = useState();
    const userData = localStorage.getItem("userdata");

    useEffect(() => {
        if (isValidJson(userData)) {
            pssApi();
        }
        else {
            navigate('/admin/login');
        }
    }, [userData]);

    const toggleSortOrder = () => {
        setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    };

    const hanldeSort = (column, sortOrder) => {
        if (sortOrder === 'desc') {
            setSortTitle('Click to Sort Ascending');
        }
        else {
            setSortTitle('Click to Sort Descending')
        }
        toggleSortOrder();
        let page = localStorage.getItem('page') || 1;
        let pageSize = localStorage.getItem('pageSize') || 10;
        setSortField(column.dataIndex);
        pssApi({ page, pageSize }, column.dataIndex);
    }
    const pssApi = async (pagination, sortField = 'createdAt') => {
        console.log(sortOrder)
        const page = pagination?.page || localStorage.getItem('page');
        const pageSize = pagination?.pageSize || localStorage.getItem('pageSize');
        const searchTerm = searchParams.get('searchTerm') || '';
        const searchTermTrimmed = searchTerm.trim();
        try {
            const response = await JOB_API('application/json').get(sortOrder === 'desc' ? `${Urls.JOB_API}?searchTerm=${searchTermTrimmed}&page=${page}&pageSize=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}` : `${Urls.JOB_API}?searchTerm=${searchTermTrimmed}&page=${page}&pageSize=${pageSize}&sortField=${sortField}`);
            if (response.data) {
                setpssData(response.data.data);
                setAllPssData(response.data);
            }
        }
        catch (error) {
            console.log(error);
            openNotification('error', 'Error', commonError);
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            sortOrder: sortOrder,
            onHeaderCell: (column) => ({
                onClick: () => hanldeSort(column, sortOrder)
            }),
        },
        {
            title: 'ContactNo',
            dataIndex: 'contactNumber',
            key: 'contactNumber',
            sorter: true,
            sortOrder: sortOrder,
            onHeaderCell: (column) => ({
                onClick: () => hanldeSort(column, sortOrder)
            }),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
            sortOrder: sortOrder,
            onHeaderCell: (column) => ({
                onClick: () => hanldeSort(column, sortOrder)
            }),
        },
        {
            title: 'Experience',
            dataIndex: 'experience',
            key: 'experience',
            sorter: true,
            sortOrder: sortOrder,
            onHeaderCell: (column) => ({
                onClick: () => hanldeSort(column, sortOrder)
            }),
        },
        {
            title: 'Designation',
            dataIndex: 'currentDesignation',
            key: 'currentDesignation',
            sorter: true,
            sortOrder: sortOrder,
            onHeaderCell: (column) => ({
                onClick: () => hanldeSort(column, sortOrder)
            }),
        },
        {
            title: 'Submitted Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            sortOrder: sortOrder,
            onHeaderCell: (column) => ({
                onClick: () => hanldeSort(column, sortOrder)
            }),
            render: (text) => {
                return (
                    moment(text).format('DD/MM/YYYY')
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: '_id',
            key: '_id',
            width: '10',
            render: (text, record) => {
                return (
                    <>
                        <Tooltip title="Download" color={"#2e8096"}>
                            <DownloadOutlined className='view-icon' onClick={() => {
                                let CV = record.CV;
                                const extension = CV.split('.');
                                getResumeById(text, record.name, extension[1]);
                            }}
                            />
                        </Tooltip>
                        <Tooltip title="View" color={"#2e8096"}>
                            <EyeOutlined className='view-icon' onClick={(record) => {
                                navigate(`/admin/jobs/view?id=${text}`);
                            }} />
                        </Tooltip>
                        <Tooltip title="Delete" color={"#db2929"}>
                            <Popconfirm
                                title="Delete"
                                description="Are You sure you want to delete data"
                                onConfirm={() => {
                                    deleteJobData(text)
                                }}
                                okText="Yes"
                                cancelText="No"
                                okType='danger'
                                icon={
                                    <InfoCircleOutlined
                                        style={{
                                            color: 'red',
                                        }}
                                    />
                                }
                            >
                                <DeleteOutlined className='delete-icon' onClick={(record) => {
                                }} /> </Popconfirm>
                        </Tooltip>
                    </>
                )
            }
        },
    ];



    const getResumeById = async (id, name, ext) => {
        await JOB_API('application/json').get(`${Urls.JOB_API}/download/${id}`, {
            responseType: 'arraybuffer',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${name}.${ext}`);
            document.body.appendChild(link);
            link.click();
            openNotification('success', 'Success', resumeSuccess)
        }).catch(error => {
            console.error("Error fetching data:", error);
            openNotification('error', 'Error', resumeError)
        });
    }

    const deleteJobData = async (id) => {
        await JOB_API('application/json').delete(`${Urls.JOB_API}/${id}`).then((response) => {
            openNotification('success', 'Success', deleteSuccess)
            reloadPage();
        }).catch(error => {
            console.error("Error fetching data:", error);
            openNotification('error', 'Error', deleteError)
        })
    }

    if (!pssData) {
        return <div>
            <Spin tip="Loading...!!" size='large'>
                <div className="content" />
            </Spin>
        </div>;
    }

    return (
        <>
            <JobsComponent
                getResumeById={(id, name, ext) => { getResumeById(id, name, ext) }}
                deleteJobData={(id) => { deleteJobData(id) }}
                columns={columns}
                pssApi={(pagination, sortField) => { pssApi(pagination, sortField) }}
                pssData={pssData}
                allPssData={allPssData}
                sortTitle={sortTitle}
                sortField={sortField}
            />
        </>
    );
};

export default JobContainer;