import React, { useState } from 'react';
import './index.css';
import { Button, Upload, Form, Input, Spin, Checkbox, message, Col, Row } from 'antd';
import { uploadVector } from "../../../../Resource/images";
import ReCAPTCHA from "react-google-recaptcha";
import { InfoCircleOutlined } from '@ant-design/icons';
import JOB_API from '../../../../Api/api'
import Urls from '../../../../Constant/Urls';
import { openNotification } from '../../../../Util/commonUtility';
import { formSubmit, commonError } from '../../../../Util/config';


const CommonForm = ({ onFormSubmit }) => {
    const [captchaToken, setCaptchaToken] = useState(null);
    const [checked, setChecked] = useState(false);
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [showUploadList, setShowUploadList] = useState(true);
    const queryParameters = new URLSearchParams(window.location.search);
    const designationValue = queryParameters.get("current_job_designation");



    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const postAPI = async (Data) => {
        await JOB_API("multipart/form-data").post(Urls.JOB_API, Data).then((response) => {
            openNotification('success', 'Success', formSubmit)
            onFormSubmit();
        }).catch(error => {
            console.log("error");
            openNotification('error', 'Error', commonError)
            console.error("Error fetching data:", error)
        })
    };

    const handleSubmit = (values) => {
        if (captchaToken !== null && checked) {
            const finalData = {
                name: values.name,
                contactNumber: values.contactNumber,
                currentJobLocation: values.currentJobLocation,
                currentCtc: values.currentCtc,
                noticePeriod: values.noticePeriod,
                email: values.email,
                experience: values.experience,
                currentOrganization: values.currentOrganization,
                expectedCtc: values.expectedCtc,
                currentDesignation: designationValue,
                CV: values.CV
            }
            setShowValidation(false);
            postAPI(finalData);
            setSubmit(true)
        } else {
            setShowValidation(true)
            return;
        }
    };
    const onChange = (e) => {
        const Checked = e.target.checked;
        if (Checked) {
            setShowCaptcha(true);
            setChecked(Checked)
        }
        else {
            setShowCaptcha(false);
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const props = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        beforeUpload: (file) => {
            const filesFormats = ["application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword", "application/pdf"];
            console.log(file);
            const isRightFormat = filesFormats.includes(file.type);
            console.log(isRightFormat);
            if (!isRightFormat) {
                message.error("You can only upload pdf and doc files");
                setShowUploadList(false);
                return;
            }
            else {
                setShowUploadList(true);
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error("File must smaller than 2MB!");
                setShowUploadList(false);
            }
            else {
                setShowUploadList(true);
            }
            return isRightFormat && isLt2M;
        },
        onChange: (info) => {
            if (info.file.status !== 'uploading') {
            }
            if (info.file.status === 'done') {
            } else if (info.file.status === 'error') {
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
    };
    return (
        <div className="commonForm-container">
            <Form
                name="resumeForm"
                onFinish={handleSubmit}
                initialValues={{
                    remember: true,
                }}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="input-container">
                    <Row gutter={20}>
                        <Col span={12}><Form.Item name='name' rules={[{ required: true, message: "Name is Required" }]}><div className='common-input-container' >
                            <Input placeholder={'Name*'}
                                className='input' />
                        </div></Form.Item>
                        </Col>
                        <Col span={12}> <Form.Item name='email' rules={[{ required: true, type: 'email', message: "Email is Required" }]}><div className='common-input-container' >
                            <Input placeholder={'Email(example@example.com)*'}
                                className='input' />
                        </div></Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}><Form.Item name='contactNumber'
                            rules={[{
                                required: true,
                                message: "Must be a valid phone number",
                                pattern: new RegExp('^[1-9]{1}[0-9]{9}$')
                            }]} ><div className='common-input-container' >
                                <Input placeholder={'Contact Number*'}
                                    className='input' />
                            </div></Form.Item>
                        </Col>
                        <Col span={12}><Form.Item name='noticePeriod' rules={[{ required: true, message: "Notice Period is Required" }]}><div className='common-input-container' >
                            <Input placeholder={'Notice Period (MONTHS)* '}
                                className='input' />
                        </div></Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}> <Form.Item name='currentJobLocation' rules={[{ required: false }]}><div className='common-input-container' >
                            <Input placeholder={'Job Location'}
                                className='input' />
                        </div></Form.Item>
                        </Col>
                        <Col span={12}> <Form.Item name='currentOrganization' rules={[{ required: false }]}><div className='common-input-container' >
                            <Input placeholder={'Current Organization'}
                                className='input' />
                        </div></Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item name='currentCtc' rules={[{ required: false }]}><div className='common-input-container' >
                                <Input placeholder={'Current CTC(LPA)'}
                                    className='input' />
                            </div></Form.Item>
                        </Col>
                        <Col span={12}><Form.Item name='expectedCtc' rules={[{ required: true, message: "ExpectedCTC is Required" }]}><div className='common-input-container' >
                            <Input placeholder={'Expected CTC(LPA)*'}
                                className='input' />
                        </div></Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={20}>
                        <Col span={24}><Form.Item name='experience' rules={[{ required: true, message: "Experience is Required" }]}><div className='common-input-container' >
                            <Input placeholder={'Experience Years and Months*'}
                                className='input' />
                        </div></Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>  <Form.Item noStyle hidden={true} name='currentDesignation' rules={[{ required: false }]}><div className='common-input-container' >
                            <Input placeholder={'Current Job Designation'} defaultValue={designationValue} disabled />
                        </div></Form.Item>
                        </Col>
                    </Row>
                </div>
                <div className="resume-container">
                    <Form.Item name='CV' getValueFromEvent={({ file }) => file.originFileObj}
                        rules={[{ required: true, message: "Resume(CV) is Required" }]}>
                        <Upload  {...props} accept=".pdf,.doc,.docx" customRequest={dummyRequest} showUploadList={showUploadList}
                        >
                            <Button icon={<img src={uploadVector} className="upload-icon" alt="vector" />
                            } className="upload-button"><span className="upload-text">Upload CV*</span></Button>
                        </Upload ></Form.Item>
                </div>
                <div className='checkbox-container'>
                    <Form.Item name='Terms and Condition' rules={[{ required: true, type: 'boolean', message: "Terms & Condition is Required" }]} valuePropName='checked'><Checkbox className='checkbox-group checkbox-content' onChange={onChange}> I have read & agreed to the terms & conditions.*</Checkbox>
                    </Form.Item>
                    {
                        showCaptcha &&
                        <div className='captcha-container'>
                            <Form.Item>
                                {!loaded && <Spin tip="Loading ReCAPTCHA!!" size='large' className='loader'>
                                    <div className="content" />
                                </Spin>}
                                <ReCAPTCHA
                                    asyncScriptOnLoad={(data) => {
                                        setLoaded(true);
                                    }}
                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                    onChange={token => {
                                        setCaptchaToken(token);

                                    }
                                    } className='recaptcha'
                                /></Form.Item>
                        </div>
                    }
                    {showValidation && <div className='validation-container'><InfoCircleOutlined /> Please complete the reCAPTCHA challenge. </div>}
                </div>
                <div className='submit-container'>
                    <Form.Item><Button className='commonForm-submit' htmlType='submit' disabled={submit ? true : false}><span className='submit-span'>Submit</span></Button></Form.Item>
                </div>
            </Form>
        </div >
    )
}

export default CommonForm;
